import { FeePrice } from '@pages/FlightBookingPage/hooks/useGetPaymentFees';
import { PaymentMethod } from '@wego/payments-react-component/dist/types/components/PaymentForm/models';
import { PaymentMethodsResponse } from '@wego/payments-react-component/dist/types/helpers/paymentOptions/models';

import { TripDetailsType } from '@helpers/flightBooking/bookingDetails/bookingDetailsType';

import { FlightFareResultTripLeg } from '@wegoTypes/flights/metasearch/flightFareResultTripLeg';

export interface FlightBookingInsurancePolicy {
  uuid: string;
  packageId: string;
  type: string;
  title: string;
  summary?: string;
  reminder: string;
  description: string;
  howToClaim: string;
  claimImageUrl: string;
  imageUrl: string;
  icon: {
    type: 'CONTENT' | 'IMAGE';
    url?: string;
    content?: string;
  };
  supplierLogo?: string;
  partnerLogo?: string;
  highlightText?: string;
  optOutText: string;
  optInText: string;
  benefitSummaries: { title: string; description: string }[];
  selectStrategy?: 'DEFAULT' | 'SHOW_OPTIONS';
  supplier: string;
  supplierProvider: 'CG' | 'HEPSTAR';
  policies: Array<{
    uuid: string;
    packageId: string;
    type: string;
    title: string;
    summary?: string;
    reminder?: string;
    description?: string;
    howToClaim?: string;
    policyImageUrl?: string;
    claimImageUrl?: string;
    pdfUrl: string;
    passengers: Array<{
      firstName: string;
      lastName: string;
      age: number;
    }>;
    benefits: Array<{
      type: string;
      title: string;
      benefitIconUrl: string;
      benefitImageUrl: string;
      covered: string;
      description: string;
      notCovered: string;
      claimLimit: number;
      claimLimitCurrency: string;
      claimLimitDescription?: string;
      claimLimitInUsd?: number;
    }>;
    price: {
      totalAmount: number;
      totalTaxAmount: number;
      totalAmountUsd: number;
      totalTaxAmountUsd: number;
      currencyCode: string;
    };
    payPrice: {
      totalAmount: number;
      totalTaxAmount: number;
      totalAmountUsd: number;
      totalTaxAmountUsd: number;
      currencyCode: string;
    };
    underwriter: {
      disclaimer: string;
      name?: string;
    };
    termsWording?: string;
  }>;
  price: {
    totalAmount: number;
    totalTaxAmount: number;
    totalAmountUsd: number;
    totalTaxAmountUsd: number;
    currencyCode: string;
  };
  payPrice: {
    totalAmount: number;
    totalTaxAmount: number;
    totalAmountUsd: number;
    totalTaxAmountUsd: number;
    currencyCode: string;
  };
  underwriter?: {
    disclaimer: string;
    name?: string;
  };
}

export interface AddonFarePrice {
  currencyCode: string;
  totalAmount: number;
  totalAmountUsd: number;
  totalTaxAmount: number;
  totalTaxAmountUsd: number;
}

// Shopcash
export interface Cashback {
  cap: number;
  currencyCode: string;
  percent: number;
}

export interface FareExchangeRate {
  code: string;
  exchangeRate: number;
  id: string;
}

// 5 digits booking statuses are defined in '/metasearch/bookings/flights/status' responses
// 6 digits booking statuses are defined by frontend

export enum BookingStatus {
  Success = 10000,
  BookingFailed = 10050,
  PaymentFailed = 70000,

  CardFormatErrorWithBookingReference = 20001,
  ThreeDSErrorWithBookingReference = 20002,
  TryAnotherCardWithBookingReference = 20003,
  GenericPaymentFailureWithBookingReference = 20050,

  CardFormatErrorWithoutBookingReference = 30001,
  ThreeDSErrorWithoutBookingReference = 30002,
  TryAnotherCardWithoutBookingReference = 30003,
  GenericPaymentFailureWithoutBookingReference = 30050,

  GeneralError = 40000,
  Polling = 50000,
  AllowRetryPayment = 60000,

  PollingAfterSuccessfulPayment = 500001,
}

// https://wegomushi.atlassian.net/wiki/spaces/FT/pages/1243054218/BoW-Flights+Backend+code+mapping#1.-Payment-status

export enum PaymentStatus {
  Blank = 0,
  FraudDeclined = 1,
  FraudFailed = 9,
  Cancelled = 5000,
  Abandon = 5001,
  Authorized = 1000,
  AuthDeclined = 1001,
  AuthPending = 1002,
  AuthPendingConfirmation = 1003,
  AuthFailed = 1009,
  Captured = 2000,
  CaptureDeclined = 2001,
  CaptureFailed = 2009,
  Voided = 2100,
  VoidDeclined = 2101,
  VoidFailed = 2109,
  Refunded = 3000,
  RefundDeclined = 3001,
  RefundFailed = 3009,
}

export interface FlightPromoCodeResponse {
  orderId: string;
  status: string;
  promotionType: string;
  code: string;
  referenceId: string;
  amount: number;
  amountUsd: number;
  currencyCode: string;
  responseCode: number;
  responseText: string;
  bins?: string[];
  applicable?: boolean;
  bankName?: string;
}

export interface PaymentCardDetails {
  bin: number;
  cardCategory: string;
  cardType: 'Credit' | 'Debit' | 'Prepaid' | 'Deferred Debit' | '' | null | undefined;
  issuer: string;
  issuerCountry: string;
  preferredMethod: 'amex' | 'masc' | 'madc' | 'visc';
  scheme: string;
}

interface FlightMetaSearchBookingStatusItineraryLegSegment {
  arrivalAirportCode: string;
  departureAirportCode: string;
  airlineRef?: string;
}

export interface FlightMetaSearchBookingStatus {
  bookingRef: string;
  expiredAt: string;
  paymentExtension: boolean;
  responseCode: BookingStatus;
  itinerary: {
    legs: Array<{ segments: Array<FlightMetaSearchBookingStatusItineraryLegSegment> }>;
  };
}

export type PassengerType = 'ADULT' | 'CHILD' | 'INFANT' | 'adult' | 'child' | 'infant';
export type TravelDocumentType = 'PASSPORT' | 'NATIONAL_ID';

export interface ContactDetails {
  allowShare?: boolean;
  fullName: string;
  email: string;
  phoneNumber: number | undefined;
  phonePrefix: number | undefined;
  countryCode: string;
}

export interface PassengerDocument {
  expiryDate?: string;
  issueDate?: string;
  number?: string;
  type?: TravelDocumentType;
}

export interface PassengerFormDetails {
  allowShare?: boolean;
  dateOfBirth: string;
  firstName: string;
  gender: 'MALE' | 'FEMALE' | undefined;
  id?: string;
  index?: number;
  isCompleted: boolean;
  lastName: string;
  nationalityCountryCode: string;
  passengerDocument?: PassengerDocument;
  travellerId: number;
  type: PassengerType;
}

export interface CreatePnrRequestPayload {
  fareId: string;
  brandedFareId: string;
  currencyCode: string;
  contact: ContactDetails;
  passengers: Array<PassengerFormDetails>;
}

export interface PassengerDetailFormFields {
  passengerForm: PassengerFormDetails;
  contactForm?: ContactDetails;
}

export type PaymentCodes =
  | 'applepay'
  | 'airtelmoney'
  | 'amex'
  | 'knet'
  | 'madc'
  | 'masc'
  | 'visc'
  | 'bnpl_3_installments'
  | 'bnpl_4_installments'
  | 'xpay';

export interface PaymentProcessingFee {
  amount: number;
  amountInUsd: number;
  currencyCode: string;
  percentage: number;
  amountMin?: number;
  amountMax?: number;
}

export interface FlightPaymentOption {
  cardType: 'CREDIT' | 'DEBIT' | 'PREPAID' | 'DEFERRED DEBIT' | '' | null | undefined;
  code:
    | 'MADA'
    | 'MADA_CREDIT'
    | 'MADA_DEBIT'
    | 'MADA_DEFERRED_DEBIT'
    | 'MADA_PREPAID'
    | 'KNET'
    | 'MASTERCARD'
    | 'MASTERCARD_CREDIT'
    | 'MASTERCARD_DEBIT'
    | 'MASTERCARD_DEFERRED_DEBIT'
    | 'MASTERCARD_PREPAID'
    | 'VISA'
    | 'VISA_CREDIT'
    | 'VISA_DEBIT'
    | 'VISA_DEFERRED_DEBIT'
    | 'VISA_PREPAID'
    | 'bnpl_3_installments'
    | 'bnpl_4_installments';
  displayOrder: number;
  defaultChargedCurrencyCode: string;
  fee: PaymentProcessingFee;
  imageUrl: string | undefined;
  name: string;
  payFee: PaymentProcessingFee;
  paymentCode: PaymentCodes;
  options: {
    approved: boolean;
    currencyCode: string;
    installments: Array<{
      amount: number;
      amountInUsd: number;
      dueDate: string;
    }>;
  };
}

export type PaymentTypes = 'card' | 'knet' | 'applepay' | 'bnpl' | 'xpay';

export interface GetPaymentResponse {
  bookingExpiredAt: string;
  bookingRef: string;
  contactEmail: string;
  createdAt: string;
  currencyCode: string;
  declinedReasonCode?: number;
  id: string;
  orderId: string;
  allowRetry?: boolean;
  statusCode: number;
  threeDsEnabled: boolean;
  totalAmountInCents: number;
  communicationMessage?: string;
  declinedReason?: string;
  last4: string;
  paymentMethodCode: PaymentCodes;
  scheme: 'AMERICAN EXPRESS' | 'American Express' | 'Mastercard' | 'Visa' | 'visa';
}

export type PaymentGateway =
  | 'wego'
  | 'knet'
  | 'applepay'
  | 'bnpl_3_installments'
  | 'bnpl_4_installments';

export interface PaymentToken {
  bin: string;
  cardCategory: string;
  cardType: string;
  expiryMonth: number;
  expiryYear: number;
  issuer: string;
  issuerCountry: string;
  last4: string;
  name: string;
  possibleMethods: Array<string>;
  scheme: string;
  token: string;
  type: string;
}

export interface PostPaymentResponse {
  createdAt: string;
  currencyCode: string;
  id: string;
  orderId: string;
  redirectLink?: string;
  threeDsEnabled: boolean;
  totalAmountInCents: number;
  allowRetry?: boolean;
  statusCode?: number;
  communicationMessage?: string;
}

export interface PostPaymentRequest {
  currencyCode: string;
  failureUrl: string;
  orderId: string;
  platform: string;
  shopcashClickId?: string;
  successUrl: string;
  token: string;
}

export enum TravelDocumentId {
  Passport = 'PASSPORT',
  NationalId = 'NATIONAL_ID',
}

export interface FlightSegmentDetails {
  airline_codes: string;
  arrival_airport_code: string;
  arrival_city_code: string;
  arrival_city_name: string;
  arrival_continent_code: string;
  arrival_continent_name: string;
  arrival_country_code: string;
  arrival_country_name: string;
  departure_airport_code: string;
  departure_city_code: string;
  departure_city_name: string;
  departure_continent_code: string;
  departure_continent_name: string;
  departure_country_code: string;
  departure_country_name: string;
  inbound_date: string;
  outbound_date: string;
}

interface FlightBookingSharedGtmPageData {
  adults_count: number;
  children_count: number;
  infants_count: number;
  client_type: string;
  currency_code: string;
  cabin_class_name: string;
  cabin_class: string;
  domain: string;
  event: string;
  flight_itinerary: string;
  legs: Array<FlightSegmentDetails>;
  locale: string;
  lowest_price_usd: number;
  lowest_price: number;
  page_name: string;
  page_sub_type: string;
  page_type: string;
  product: string;
  provider_code: string;
  provider_type: string;
  search_id: string;
  session_id: string;
  site_code: string;
  total_price_usd_before_tax?: number;
  trip_category: string;
  trip_type: string;
  ts_code: string | null;
  url: string;
  user_country_code: string;
  user_id: string | null;
}

export interface FlightComparisonGtmPageData extends FlightBookingSharedGtmPageData {
  provider_code: string;
  provider_type: string;
  lowest_price: number;
  lowest_price_usd: number;
  e_id?: string;
}

export interface FlightBookingGtmPageData extends FlightBookingSharedGtmPageData {
  cabin_class_name: string;
  total_price: number;
  total_price_usd: number;
  click_id: string;
  e_id?: string;
}

export interface FlightBookingConfirmationGtmPageData extends FlightBookingSharedGtmPageData {
  booking_id: string;
  booking_status: string;
  booking_status_code: BookingStatus;
  total_price: number;
  total_price_usd: number;
  click_id: string;
  e_id?: string;
}

export interface UtaDesc {
  attributes?: {
    amount?: number;
    currencyCode?: string;
    pieceCount?: number;
    unit?: string;
    weight?: number;
  };
  /**
   * @deprecated Use key. Currently desktop version is still using it.
   */
  code: string;
  id: number;
  /**
   * @variation allowed_no_fee
   * @variation allowed_with_fee
   * @variation included
   * @variation not_allowed
   * @variation not_included
   * @variation unknown
   */
  key: string;
  name?: string;
  originalCode?: string;
  type: string;
}

// https://github.com/wego/wego-docs/blob/master/flights/wego_fares/revalidation.md

export interface FareExchangeRate {
  code: string;
  exchangeRate: number;
  id: string;
}

export interface Penalty {
  type: string;
  amount?: number;
  amountUsd?: number;
  currencyCode: string;
  conditionsApply?: boolean;
}

export interface BrandedFarePrice {
  currencyCode: string;
  totalAmount: number;
  totalAmountUsd: number;
  totalBookingFee: number;
  totalBookingFeeUsd: number;
  totalOriginalAmount: number;
  totalOriginalAmountUsd: number;
  totalTaxAmount: number;
  totalTaxAmountUsd: number;
}

interface BrandedFarePassengerInfoPrice {
  amount: number;
  amountUsd: number;
  currencyCode: string;
  originalAmount: number;
  originalAmountUsd: number;
  taxAmount: number;
  taxAmountUsd: number;
  totalAmount: number;
  totalAmountUsd: number;
  totalOriginalAmount: number;
  totalOriginalAmountUsd: number;
  totalTaxAmount: number;
  totalTaxAmountUsd: number;
}

export interface BrandedFarePassengerInfo {
  // TODO check between versions of compare response
  baggages: Array<Array<Array<number>>>;
  fees?: Array<number>;
  penalties?: Array<Penalty>;
  price: BrandedFarePassengerInfoPrice;
  taxes: Array<number>;
  type: 'ADULT' | 'CHILD' | 'INFANT';
}

interface BrandedFareBrand {
  code: string;
  name: string;
}

export interface BrandedFare {
  id: string;
  brandName: string;
  price: BrandedFarePrice;
  payPrice: BrandedFarePrice;
  penalties: Array<Penalty>;
  cabins: Array<string>;
  legId?: string | number;
  passengerInfos: Array<BrandedFarePassengerInfo>;
  refundType: string;
  utas: Array<number>;
  tags: Array<{ attributes?: FlightCashback; code: string; id: number }>;
  services?: Array<UtaDesc>; // to hold Uta items to be rendered
  brands: {
    legId: number;
    name: string;
  }[];
  /**
   * @deprecated
   */
  brand?: BrandedFareBrand;
  isMixedBrandedFare: boolean;
}

export interface FareComparisonAirlineDisclaimerDesc {
  id: number;
  note: string;
}
export interface FareComparisonBaggageDesc {
  id: number;
  type: string;
  pieceCount?: number;
  note?: string;
  weightText?: string;
  dimensionText?: string;
  weight?: number;
  unit?: string;
  included?: boolean;
}
export interface FeeDescs {
  amount: number;
  id: number;
  type: string;
}
export interface TaxDesc {
  id: number;
  code: string;
  description: string;
  amount: number;
  amountUsd: number;
  currencyCode: string;
}

interface FareTripDetails {
  id: string;
  legs: Array<FlightFareResultTripLeg>;
  domestic?: boolean;
}

export interface FareComparison {
  airlineDisclaimerDescs: Array<FareComparisonAirlineDisclaimerDesc>;
  airlineDisclaimerViews: Array<{
    airlineDisclaimer: number;
    legId: number;
  }>;
  baggageDescs: Array<FareComparisonBaggageDesc>;
  /**
   * @deprecated v2 APIs
   */
  baggageTermsAndConditions: Array<string>;
  baggageTermsAndConditionViews: Array<{
    legId: number;
    baggageTermsAndConditions: Array<string>;
  }>;
  brandedFares: Array<BrandedFare>;
  currencyDescs: Array<FareExchangeRate>;
  feeDescs: Array<FeeDescs>;
  expiredAt: string;
  priceInfo: {
    currencyCode: string;
    newTotalAmount: number;
    newTotalAmountInUsd: number;
    oldTotalAmount: number;
    oldTotalAmountInUsd: number;
    priceChanged: boolean;
  };
  taxDescs: Array<TaxDesc>;
  termsAndConditionViews: Array<{
    legId: number;
    termsAndConditions: Array<string>;
  }>;
  trip: FareTripDetails;
  utaDescs: Array<UtaDesc>;
  /**
   * @deprecated
   */
  passportNeeded: boolean;
}

export interface ConfirmAddonsResponse {
  bookingRef: string;
  createdAt: string;
  expiredAt: string;
  paymentOrderId: string;
  uuid: string;
  price: {
    currencyCode: string;
    totalAmount: number;
    totalAmountUsd: number;
    totalBookingFee: number;
    totalBookingFeeUsd: number;
    totalInsuranceAmount: number;
    totalInsuranceAmountUsd: number;
    totalOriginalAmount: number;
    totalOriginalAmountUsd: number;
    totalTaxAmount: number;
    totalTaxAmountUsd: number;
  };
  totalAmount: number;
  insurancePackages:
    | [
        {
          uuid: string;
          packageId: string;
          type: string;
          title: string;
          summary?: string;
          reminder: string;
          description: string;
          howToClaim: string;
          claimImageUrl: string;
          imageUrl: string;
          icon: {
            type: 'CONTENT' | 'IMAGE';
            url?: string;
            content?: string;
          };
          supplierLogo?: string;
          partnerLogo?: string;
          highlightText?: string;
          optOutText: string;
          optInText: string;
          benefitSummaries: { title: string; description: string }[];
          supplier: string;
          supplierProvider: 'CG' | 'HEPSTAR';
          policies: Array<{
            uuid: string;
            packageId: string;
            type: string;
            title: string;
            summary?: string;
            reminder?: string;
            description: string;
            howToClaim: string;
            policyImageUrl: string;
            claimImageUrl: string;
            pdfUrl: string;
            passengers: Array<{
              firstName: string;
              lastName: string;
              age: number;
            }>;
            benefits: Array<{
              type: string;
              title: string;
              benefitIconUrl: string;
              benefitImageUrl: string;
              covered: string;
              description: string;
              notCovered: string;
              claimLimit: number;
              claimLimitCurrency: string;
              claimLimitDescription?: string;
              claimLimitInUsd?: number;
            }>;
            price: {
              totalAmount: number;
              totalTaxAmount: number;
              totalAmountUsd: number;
              totalTaxAmountUsd: number;
              currencyCode: string;
            };
            payPrice: {
              totalAmount: number;
              totalTaxAmount: number;
              totalAmountUsd: number;
              totalTaxAmountUsd: number;
              currencyCode: string;
            };
            underwriter: {
              disclaimer: string;
              name?: string;
            };
          }>;
          price: {
            totalAmount: number;
            totalTaxAmount: number;
            totalAmountUsd: number;
            totalTaxAmountUsd: number;
            currencyCode: string;
          };
          payPrice: {
            totalAmount: number;
            totalTaxAmount: number;
            totalAmountUsd: number;
            totalTaxAmountUsd: number;
            currencyCode: string;
          };
          underwriter?: {
            disclaimer: string;
            name?: string;
          };
        },
      ]
    | '';
}

interface FormFieldConditions {
  nationalities: Array<string>;
}

export interface FormField {
  field: string;
  optional: boolean;
  conditions?: FormFieldConditions | undefined;
}

export interface DynamicFormRules {
  adultPassengerForm: Array<FormField>;
  childPassengerForm: Array<FormField> | undefined;
  contactForm: Array<FormField>;
  infantPassengerForm: Array<FormField> | undefined;
}

export interface FareRule {
  departureCityName: string;
  departureAirportCode: string;
  arrivalCityName: string;
  arrivalAirportCode: string;
  airline: {
    code: string;
    name: string;
  };
  fareRules: {
    adult?: Array<{
      title?: string;
      text: string;
    }>;
    child?: Array<{
      title?: string;
      text: string;
    }>;
    infant?: Array<{
      title?: string;
      text: string;
    }>;
  };
}

export interface FlightBackendExchangeRate {
  code: string;
  exchangeRate: number;
  id: string;
}

export interface FlightBookingDetailsSessionStorage {
  bookingRef: string;
  insurancePolicies: Array<FlightBookingInsurancePolicy>;
  isAddonsConfirmed: boolean;
  selectedInsurancePolicyIds: Array<string>;
  flightPnrDetails: FlightBookingPnrDetails;
  passengerForms: Array<PassengerFormDetails>;
  contactDetails: ContactDetails;
  paymentId: string;
  flightFareDetails: FareComparison;
  flightFareRules: Array<Array<FareRule>>;
  selectedPaymentOption: FlightPaymentOption;
  koletConsent: boolean;
  searchParams: {
    locale?: string;
    adultCount: string;
    cabinClass: string;
    childCount: string;
    fareCode: string;
    infantCount: string;
    leg: string;
    searchId: string;
    tripCode: string;
  };
  totalAddonsFare: AddonFarePrice;
  bookingConfirmationDetails: ConfirmAddonsResponse;
  selectedPaymentMethod?: PaymentMethod; // used to store selected payment method for payment form, will replace selectedPaymentOption in the future
  newSelectedPaymentOption?: PaymentMethodsResponse | undefined; // used to store selected payment method for payment form, will replace selectedPaymentOption in the future
  processingFee?: FeePrice; // used to store processing fee for payment form, will replace selectedPaymentOption.fee in the future
  defaultCurrencyOfPaymentMethod?: string; // used to store default currency of selected payment method for payment form
  seatAvailable?: boolean;
  legDetails: Array<TripDetailsType>;
}

export interface FlightBookingPnrDetails {
  bookingRef: string;
  createdAt: string;
  expiredAt: string;
  lastTicketingDate: string;
  paymentOrderId: string;
  totalAmount: number;
  errors: Array<{
    code: string;
    message: string;
  }>;
}

export interface FlightBookingCreatePnrRequestPayload {
  fareId: string;
  brandedFareIds: Array<string>;
  currencyCode: string;
  contact: ContactDetails;
  passengers: Array<PassengerFormDetails>;
  koletConsent: boolean;
}

export interface FlightCashback {
  cap: number;
  currencyCode: string;
  percent: number;
}

export interface FlightFareId {
  currencyCode: string;
  domain: string;
  flightId: string;
  locale: string;
  msFareId: string;
  searchId: string;
  searchPath: string;
}

export interface FlightPaymentOptions {
  accountId: string;
  currencyCode: string;
  publicKey: string;
  name: string;
  paymentMethods: Array<FlightPaymentOption>;
}

export interface TravellerDetails {
  travellers: Array<TravellerList>;
}

export interface TravellerList {
  date_of_birth: string;
  first_name: string;
  gender: 'MALE' | 'FEMALE' | null;
  id: number;
  last_name: string;
  middle_name: string | null;
  nationality: string | null;
  passport_expiry: string | null;
  passport?: string;
  title: string | null;
  traveller_id_country: string | null;
  traveller_id_expiry: string | null;
  traveller_id_issued_at?: string | null;
  traveller_id_no?: string;
  traveller_id_type?: TravelDocumentType | null;
  traveller_type: PassengerType | null;
  updated_at: string;
}

export interface TravellerDetailsRequestPayload {
  travellers: Array<{
    date_of_birth?: string;
    first_name: string;
    gender?: string;
    id?: string | number;
    last_name: string;
    middle_name?: string;
    nationality?: string;
    title?: string;
    traveller_id_country?: string;
    traveller_id_expiry?: string;
    traveller_id_issued_at?: string;
    traveller_id_no?: string;
    traveller_id_type?: TravelDocumentType;
    traveller_type?: PassengerType;
  }>;
}

export interface BookingPageRouteState {
  flightFares: FareComparison;
  flightAmenities: FlightAmenities;
  currentStep: number;
  activePaymentOrder?: {
    bookingPaymentStatus?: GetPaymentResponse;
    flightPnrDetails: FlightBookingPnrDetails;
    id: string;
    insurancePolicies: Array<FlightBookingInsurancePolicy>;
    selectedInsurancePolicyIds: Array<string>;
  };
}
